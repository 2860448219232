/**
 * Created by jerry on 2020/03/30.
 * 项目经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取项目经手人设置列表
    getProjectSaleHandler: params => {
        return API.POST('api/projectSaleHandler/all', params)
    },
    // 创建项目经手人设置
    createProjectSaleHandler: params => {
        return API.POST('api/projectSaleHandler/create', params)
    },
    // 更新项目经手人设置
    updateProjectSaleHandler: params => {
        return API.POST('api/projectSaleHandler/update', params)
    },
    // 调整项目经手人设置顺序
    moveProjectSaleHandler: params => {
        return API.POST('api/projectSaleHandler/move', params)
    },
}
